.categoriesList {
    direction: rtl;

    &::-webkit-scrollbar {
        width: 0.4rem;
    }

    &::-webkit-scrollbar-track {
        background: var(--chakra-colors-gray-100);
        border-radius: 0.2rem;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--chakra-colors-gray-300);
        border-radius: 0.2rem;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(--chakra-colors-gray-400);
    }

    li {
        direction: ltr;
    }
}
