.nextImage {
    transition: all 700ms ease-in-out;
    transform-origin: center;
}

.loading {
    filter: grayscale(1) blur(1.5rem);
    transform: scale(1.05) rotate(0.00000000001deg);
}

.loaded {
    filter: grayscale(0) blur(0);
    transform: scale(1) rotate(0.00000000001deg);
}
