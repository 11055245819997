.swiperThumbs {
    width: 100%;
    height: 100%;

    &.swiper {
        max-height: 560px;
    }

    .swiper-slide {
        min-height: 130px;
    }

    img {
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
    }

    &:not(.swiper-slide-thumb-active) {
        img {
            border: 1px solid #f1f5f9;
        }
    }

    .swiper-slide-thumb-active {
        img {
            border: 4px solid #0085ca;
        }
    }
}
.swiperImages {
    height: 100%;
    transition: 0.5s;

    .swiper-slide {
        height: auto;
    }

    &.fullScreenSwiperImages {
        img {
            border-radius: 0;
            max-height: 100%;
        }
    }

    img {
        width: 100%;
        max-height: 560px;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
    }

    .group {
        display: flex;
    }
}

.swiper,
.swiper-wrapper {
    max-height: inherit;
}

.swiper.fullScreenSwiperImages {
    display: none;
}

.swiper.fullScreenSwiperImages.swiper-fullscreen {
    display: flex;
}

.swiper.swiper-fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(black, 0.8);
    z-index: 1000;
    max-height: unset;
    img {
        margin: auto;
        width: auto;
    }

    .swiper-slide {
        padding: 1rem;
    }
}
.chakra-button.swiperCloseButton {
    display: none;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 1001;
    &.show {
        display: flex;
    }
}
