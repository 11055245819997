.listMenu {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-left: 0;
    list-style: none;

    li {
        display: block;
        padding: 0;
        margin: 0;
    }

    a {
        display: block;

        &:active {
            text-decoration: none;
        }
    }

    button {
        font-weight: normal;
    }
}
