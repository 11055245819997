.shoppingCartBody {
    &::-webkit-scrollbar {
        width: 0.625rem;
    }

    &::-webkit-scrollbar-track {
        background: var(--chakra-colors-gray-100);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--chakra-colors-gray-300);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(--chakra-colors-gray-400);
    }
}
