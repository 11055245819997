.navigation {
    li {
        padding: 0;

        &:not(:last-child) {
            position: relative;
            margin-right: 1.75rem;

            &:after {
                position: absolute;
                top: 50%;
                right: -0.875rem;
                line-height: 1;
                content: '|';
                transform: translateY(-50%);
            }
        }
    }
}
